import * as React from "react"
import Layout from '../components/layout'

import {
  center,
  container,
} from '../components/layout.module.css'

// markup
const NotFoundPage = () => {
  return (
    <Layout pageTitle="Error 404">
      <div className={container +' '+ center}>
        <h1>404</h1>
        <p>This page doesn't exist.</p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
